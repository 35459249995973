import React from "react";
import styled from "styled-components";

import background from "./assests/bgTwo.jpg";

const StyledHome = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
  position: relative;

  justify-content: center;

  flex-direction: column;
  /* background-color: ${(props) => props.backgroundColor}; */
  .secret {
    position: absolute;
    right: 30px;
    bottom: 30px;
    color: white;
    font-size: 5px;
    font-family: Montserrat, sans-serif;
  }
  iframe {
    transform: translateY(${(props) => props.styleChange && "-50px"});
  }
`;

const App = () => {
  return (
    <>
      <img
        src={background}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      <StyledHome>
        <iframe
          title="here-it-is"
          style={{ background: "#FFF" }}
          width="1024"
          height="768"
          src={`https://jolly-kowalevski-623b54.netlify.app/home`}
        />
      </StyledHome>
    </>
  );
};

export default App;
